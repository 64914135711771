import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import InfoPageLayout from '../components/InfoPageLayout'
import Seo from '../components/Seo'

export default function InfoPageRepater({ data }) {
  const { wpPage: { info_repeater, title, language, translations }, allWpMenu, allWp: { nodes: [optionsPage] } } = data
  return (
    <div className="info-page-template">
      <Seo title={title} lang={language.locale} />
      <InfoPageLayout menuData={allWpMenu} translations={translations} infoRepeater={info_repeater} pageTitle={title} language={language} footerOptionsPage={optionsPage} />
    </div>
  )
}

InfoPageRepater.propTypes = {
  data: PropTypes.object
}
export const infoRepeaterQuery = graphql`
  query infoPageByID($id: String) {
    wpPage(id: {eq: $id}) {
      id
      slug
      title
      info_repeater {
        subtitle
        repeater {
          smallOrBigImage
          description
          hasLink
          title
          url
          icon {
            localFile {
              childImageSharp {
                fluid(maxWidth: 100){
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
      language {
        locale
      }
      translations {
        uri
      }
    }
    allWpMenu {
      ...getMenus
    }
    allWp {
      nodes {
        ...getOptionsPageFooterData
      }
    }
  }
`
